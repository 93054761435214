import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { payoutService } from '../../../_services/payout.service';
import { IoMdClose } from 'react-icons/io';
import ReceiptModal from '../../ReceiptModal';
import { propertyService } from '../../../_services/property.service';
import * as moment from 'moment-timezone';

const COLD = 'Cold bed';

const RefundReceipt = ({ details, onClose }) => {
  const getReservationDates = () => {
    if(details.bookingId.isMonthlyCheckout === 2){
      if(details.checkInDate && details.endDate){
        const startDate = moment(details.checkInDate.split("T")[0]);
        const endDate = moment(details.endDate.split("T")[0])
        const isSameMonth = startDate.month() === endDate.month();
        return `${startDate.format("MMMM Do, YYYY")} - ${endDate.format("MMMM Do, YYYY")}`
        // return isSameMonth?`${startDate.format("MMMM Do, YYYY")} - ${endDate.format("MMMM Do, YYYY")}`:`${startDate.format("MMMM Do, YYYY")} - ${endDate.subtract(1, 'days').format("MMMM Do, YYYY")}`
      }else{
        const startDate = moment(details.bookingId.checkInDate.split("T")[0]);
        const endDate = moment(details.bookingId.checkOutDate.split("T")[0]) 
       return  `${startDate.format("MMMM Do, YYYY")} - ${endDate.format("MMMM Do, YYYY")}`
      }
    }else{
      return `${moment(details.bookingId.checkInDate.split("T")[0]).format(
        "MMMM Do, YYYY"
      )}
       - ${moment(
        details.bookingId.checkOutDate.split("T")[0]
      ).format("MMMM Do, YYYY")}`
    }
  }

  return (
      <div className="tw-w-[95%] tw-text-sm lg:w-[65%]  tw-mx-auto">
          <div className="tw-bg-white tw-p-4 tw-rounded-md tw-relative ">
              <span onClick={onClose} className="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer">
                  <IoMdClose size={32} />
              </span>

              <h2 className="tw-text-3xl tw-font-medium">Your Receipt from Crewmates</h2>
              <p className="tw-text-gray-500">
                  Receipt ID: {details.receiptId} {details.bookingId.updatedAt && `- ${moment(details.updatedAt).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
              </p>
              <h3 className="tw-text-2xl tw-font-medium tw-mt-2">Reservation Details</h3>

              <p className="tw-my-4">
              {details.bookingId && details.bookingId.checkInDate && getReservationDates()}
                  {/* {details.bookingId && details.bookingId.checkInDate && (
                      <>
                          {moment(details.bookingId.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}
                          {` - ${moment(details.bookingId.checkOutDate.split('T')[0]).format('MMMM Do, YYYY')}`}
                      </>
                  )} */}
                  <br />
                  Room {details.bookingId && details.bookingId.roomName}, Bed {details.bookingId && details.bookingId.bedName} <br />
                  Security Deposit : {details.bookingId &&
                    `${details.bookingId.securityDeposite.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}`} <br />
                  Confirmation code : {details.bookingId && details.bookingId.confirmationCode}
              </p>
              {/* <h4 className="tw-text-xl tw-font-medium">Cancellation Policy</h4>
              {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Relaxed' ? (
                  <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                      <span className="tw-text-gray-500">Relaxed: </span> Guests can receive a full refund up until 24 hours before check-in
                  </p>
              ) : null}
              {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Moderate' ? (
                  <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                      <span className="tw-text-gray-500">Moderate: </span> Guests can receive a full refund up until 5 days before check-in
                  </p>
              ) : null}
              {details.bookingId && details.bookingId.cancelationPolicy[0] === 'Strict' ? (
                  <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                      <span className="tw-text-gray-500">Strict: </span> No refunds
                  </p>
              ) : null} */}

              <h4 className="tw-text-xl tw-font-medium">Refund Details</h4>
              <div className="tw-grid tw-grid-cols-2 tw-my-4 tw-text-gray-500   ">
                  <p>Amount Refunded</p>
                  <p className="tw-text-right">
                      {details.refundAmount &&
                          `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                          })}`}
                  </p>

                  <hr className="tw-col-span-full tw-my-2" />
                  <p className="tw-font-medium tw-text-gray-800">Total</p>
                  <p className="tw-font-medium tw-text-right tw-text-gray-800">
                      {details.refundAmount &&
                          `$${parseFloat(details.refundAmount).toFixed(2).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                          })}`}
                  </p>
              </div>
              <hr className="tw-col-span-full" />
             <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
            <div className="flex-col flex">
              <h4 className="text-xl font-medium mb-3">Payment</h4>
              <p className="tw-text-gray-500">{details.cardDetail}</p>
              <p className="tw-text-gray-500">
                {moment(details.createdAt).tz(details.bookingId.timezone).format(
                  // "MMMM DD, YYYY h:mm:ss zz"
                  "MMMM Do, YYYY h:mm:ss zz"
                )}{" "}
                {/* EST */}
              </p>
            </div>
            <p className="tw-text-right tw-text-gray-500">
              {details.refundAmount &&
                `$${parseFloat(details.refundAmount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div>
          <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2 tw-text-gray-800   ">
            <p className="tw-font-medium font-medium">Amount Paid</p>
            <p className="tw-font-medium tw-text-right font-medium">
              {details.refundAmount &&
                `$${parseFloat(details.refundAmount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div>
          </div>
      </div>
  )
}

function ReservationDetails({ details }) {
  console.log(
    'details information passed in bookingDetails.js code ::::',
    details
  );

  const history = useHistory();

  const [transactionList, setTransactionList] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [refundDetails, setRefundDetails] = useState({
    reufndData:[],
    individualRefund:{},
  });
  const [fetching, setFetching] = useState(false);
  const [showRefundReceipt, setShowRefundReceipt] = useState(false)

  // useEffect(() => {
  //   const fetchTransactions = async () => {
  //     try {
  //       const { data } = await payoutService.getTransactions({
  //         bookingId: details._id,
  //       });

  //       setTransactionList(data.data);
  //     } catch (error) {
  //       toast.error('Something went wrong!');
  //     } finally {
  //     }
  //   };
  //   if (details._id) fetchTransactions();
  // }, [details]);
  const fetchTransactions = async (id) => {
    try {
      console.log("Id -->", id)
      const { data } = await payoutService.getTransactions({
        bookingId: id,
      });
      setTransactionList(data.data);
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
    }
  };

  useEffect(() => {
    if (details._id) {
      fetchTransactions(details._id);
    } 
  }, [details._id]);

  useEffect(() => {
    const fetchRefundDetails = async () => {
      try {
        const { data } = await propertyService.bookingRefundStatus({
          bookingId: details._id,
        });
        console.log("refund data ==>>> ",data);
        if (data.length) {
          setRefundDetails(state=>({
            ...state,
            reufndData:data
          }));
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");
      } finally {
      }
    } 
    if (details._id) fetchRefundDetails();
  }, [details]);
  
  function handleMessageNavigation() {
    history.push({
      pathname: '/message',
      state: {
        id: `${details.propertyId._id}-${details.userId.id}-${details.hostId.id}`,
      },
    });
  }
  const getWholeBookingPrice = () => {
    if(transactionList.length > 0){
      let sumOfAllTransactionAmounts = 0;
      for(const tr of transactionList){
        sumOfAllTransactionAmounts += parseFloat(tr.totalPrice);
      }
      return sumOfAllTransactionAmounts;
    }else{
      if(details.upcommingBookingHistoryId){
        return details.upcommingBookingHistoryId.totalPrice
      }else{
        return details.totalPrice;
      }
    }
  }

  /* Function added to show cold bed receipt info */
  /* Change added on 10-28-2022 */
  const ColdBedReceipt = ({ onClose, transactionId }) => {
    const [fetching, setFetching] = useState(true);
    const [details, setReceiptDetails] = useState({});
    const [monthBookingPrice, setMonthBookingPrice] = useState(0);

    useEffect(
      () => {
        const fetchTransactionDetails = async () => {
          try {
            setFetching(true);
            const { data } = await payoutService.getTransactionDetails({
              transactionId,
            });
            const transactionDetails = data.data;

            console.log(
              'Inside cold bed receipt transactionDetails value received :::',
              transactionDetails
            );

            if (transactionDetails && transactionDetails.bookingId) {
              setReceiptDetails(transactionDetails);

              console.log(
                'Now received details value OP in cold bed receipt :::',
                typeof transactionDetails,
                details
              );
            } else {
            }

            if (transactionDetails.bookingId) {
              let totalDays =
                moment(transactionDetails.endDate).diff(
                  moment(transactionDetails.startDate),
                  'days'
                ) + 1;
              let numberOfDayInBookingMonth = moment(
                transactionDetails.bookingId.checkInDate
              ).daysInMonth();

              console.log('Number of days in booking month OP :::', {
                numberOfDayInBookingMonth,
                bookingPrice: transactionDetails.bookingId.bookingPrice,
              });

              let perDayPrice =
                transactionDetails.bookingId.bookingPrice /
                numberOfDayInBookingMonth;
              console.log(
                'Per Day Price and totalDays value OP to get monthBookingPrice ::::',
                perDayPrice,
                totalDays
              );
              // setMonthBookingPrice(perDayPrice * totalDays);
              setMonthBookingPrice(
                transactionDetails.bookingId.totalPrice -
                  transactionDetails.bookingId.cleaningFee
              );
            }
          } catch (error) {
            toast.error('Something went wrong!');
          } finally {
            setFetching(false);
          }
        };
        if (transactionId) fetchTransactionDetails();
      },
      [transactionId],
      [details]
    );
    var discountOfLineholder = 0;
    if (Object.keys(details).length > 0) {
      if (Object.keys(details.bookingId).length > 0) {
        if (details.discountOfLineholder) {
          discountOfLineholder = details.discountOfLineholder;
        }
      }
    }

    const getReservationDates = () => {
      const datesForNormalBooking = `${moment(details.startDate.split('T')[0]).format(
        'MMMM Do, YYYY'
      )} - ${
        details.bookingId.isMonthlyCheckout == 1
          ? moment(
              details.bookingId.checkOutDate.split('T')[0]
            ).format('MMMM Do, YYYY')
          : !details.bookingId.isNotice ||
            details.endDate.split('T')[0] !=
              details.bookingId.checkOutDate.split('T')[0]
          ? moment(details.endDate.split('T')[0])
              .add(1, 'days')
              .format('MMMM Do, YYYY')
          : moment(
              details.bookingId.checkOutDate.split('T')[0]
            ).format('MMMM Do, YYYY')
      }`
      console.log("details.bookingId.isMonthlyCheckout==>> ",details.bookingId.isMonthlyCheckout,details.bookingId.isMonthlyCheckout === 2)
      if(details.bookingId.isMonthlyCheckout === 2){
        if(details.startDate && details.endDate){
          const startDate = moment(details.startDate.split("T")[0]);
          const endDate = moment(details.endDate.split("T")[0])
          const isSameMonth = startDate.month() === endDate.month();
          return `${startDate.format("MMMM Do, YYYY")} - ${endDate.format("MMMM Do, YYYY")}`
        }else{
         return datesForNormalBooking
        }
      }else{
        return datesForNormalBooking
    }
  }

  const shouldAutopayShow = () => {
    if(details){
        const startDate = moment(details.startDate)
        const bookingCheckInDate = moment(details.bookingId.checkInDate)
        if(startDate.month() === bookingCheckInDate.month()){
            return false;
        }else{
            return true
        }
    }else{
        return false;
    }
}

    return (
      <>
        {fetching ? (
          <div className="tw-rounded-md tw-p-8 tw-grid tw-place-content-center tw-bg-white">
            Loading...
          </div>
        ) : (
          <div className="tw-bg-white p-4 tw-rounded-md tw-relative">
            <span
              onClick={onClose}
              className="tw-right-6 tw-top-6 tw-cursor-pointer align-right"
            >
              <IoMdClose size={32} />
            </span>

            <h2 className="text-3xl font-medium">
              Your Receipt from Crewmates
            </h2>
            
            <p>
              Receipt ID: {details.receiptId}{' '}
              {details.transactionDate &&
                `- ${moment(details.transactionDate).tz(details.bookingId.timezone).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
            </p>
            <h3 className="text-2xl font-medium mt-2">Reservation Details</h3>
            <p className="my-4">
              {details.bookingId && getReservationDates()}
              {/* {details.bookingId && (
                <>
                  {moment(details.startDate.split('T')[0]).format(
                    'MMMM Do, YYYY'
                  )}
                  {` - ${
                    details.bookingId.isMonthlyCheckout == 1
                      ? moment(
                          details.bookingId.checkOutDate.split('T')[0]
                        ).format('MMMM Do, YYYY')
                      : !details.bookingId.isNotice ||
                        details.endDate.split('T')[0] !=
                          details.bookingId.checkOutDate.split('T')[0]
                      ? moment(details.endDate.split('T')[0])
                          .add(1, 'days')
                          .format('MMMM Do, YYYY')
                      : moment(
                          details.bookingId.checkOutDate.split('T')[0]
                        ).format('MMMM Do, YYYY')
                  }`}
                </>
              )} */}
              <br />
              Cold bed {shouldAutopayShow()?'Autopay':''} <br />
              Room {details.bookingId && details.bookingId.roomName}, Bed{' '}
              {details.bookingId && details.bookingId.bedName} <br />
              Security Deposit : {details.bookingId &&
                    `${details.bookingId.securityDeposite.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}`} <br />
              Confirmation code :{' '}
              {details.bookingId && details.bookingId.confirmationCode}
            </p>

            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy ? (
              <h4 className="text-xl font-medium">Cancellation Policy</h4>
            ) : null}

            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Relaxed' ? (
              <p className="text-sm text-gray-500 my-2">
                <span className="text-gray-500">Relaxed: </span> Guests can
                receive a full refund up until 24 hours before check-in
              </p>
            ) : null}
            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Moderate' ? (
              <p className="text-sm text-gray-500 my-2">
                <span className="text-gray-500">Moderate: </span> Guests can
                receive a full refund up until 5 days before check-in
              </p>
            ) : null}
            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Strict' ? (
              <p className="text-sm text-gray-500 my-2">
                <span className="text-gray-500">Strict: </span> No refunds
              </p>
            ) : null}
            {/* <p className="text-sm text-gray-500 my-2">
              <span>Security Deposit: </span>
              {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
            </p> */}

            <h4 className="text-xl font-medium">Price Breakdown</h4>
            <div className="tw-grid tw-grid-cols-2  ">
              {/* <p>Prorated cold bed</p>
                <p className="tw-text-right">
                  {monthBookingPrice &&
                    monthBookingPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                </p> */}
                {
                  details.bookingId.isMonthlyCheckout == 2 ?
                                <p className='tw-text-gray-500'>Prorated Cold Bed</p>
                            :    <p className='tw-text-gray-500'>Cold Bed</p>

                }
{/*              <p className='tw-text-gray-500'>Prorated Cold Bed</p> */}
              <p className="tw-text-right tw-text-gray-500">
                {details.monthBookingPrice &&
                  `$${parseFloat(details.monthBookingPrice)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              {details.extraGuestCharge > 0 && (
                <>
                  <p className='tw-text-gray-500'>Extra Guest Charge</p>
                  <p className="tw-text-right tw-text-gray-500">
                    {details.extraGuestCharge &&
                      `$${parseFloat(details.extraGuestCharge)
                        .toFixed(2)
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}`}
                  </p>
                </>
              )}
              <p className='tw-text-gray-500'>Cleaning Fee</p>
              <p className="tw-text-right tw-text-gray-500">
                {details.cleaningFee &&
                  `$${parseFloat(details.cleaningFee)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              {details.bookingId && details.discountOfLineholder && (details.discountOfLineholder > 0) ? (
                <React.Fragment>
                  <p className='tw-text-gray-500'>Lineholder Discount</p>
                  <p className="tw-text-right tw-text-gray-500">
                    {`$${parseFloat(details.discountOfLineholder)
                      .toFixed(2)
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}`}
                  </p>
                </React.Fragment>
              ) : null}
              {/* <p>Security Deposit</p>
                <p className="tw-text-right">
                  {details.bookingId &&
                    details.bookingId.securityDeposite.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                </p> */}
             
              <p className='font-medium tw-font-medium tw-text-gray-500'>Total</p>
              <p className="tw-text-right tw-text-gray-500">
                {details.bookingId &&
                 `$${parseFloat(details.totalPrice)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
             
            </div>
              <hr className="tw-col-span-full" />
              <div className="tw-grid tw-grid-cols-2 tw-text-gray-800 mt-3 d-flex tw-items-center align-items-center justify-content-between">
                <div className='flex-col flex'> 
                  <h4 className="text-xl font-medium mb-3">Payment</h4>
                  <p className='tw-text-gray-500'>{details.cardDetails}</p>
                  <p className='tw-text-gray-500'>{moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY h:mm:ss')} </p>
                </div>
                <p className="tw-text-right tw-text-gray-500">
                  {details.bookingId &&
                    `$${parseFloat(details.totalPrice)
                      .toFixed(2)
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}`}
                </p>
              </div>
              <hr className="tw-col-span-full" />
              <div className="tw-grid tw-grid-cols-2 tw-text-gray-800   ">
              <p className="tw-font-medium font-medium">Amount Paid</p>
              <p className="tw-font-medium tw-text-right font-medium">
                {details.bookingId &&
                  `$${parseFloat(details.totalPrice)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              </div>
            </div>
        )}
      </>
    );
  };

  /* Function added to show hot bed receipt info */
  /* Change added on 10-28-2022 */
  const HotBedReceipt = ({ onClose, transactionId }) => {
    const [fetching, setFetching] = useState(true);
    const [details, setHotBedReceiptDetails] = useState({});
    const [monthBookingPrice, setMonthBookingPrice] = useState(0);

    useEffect(() => {
      const fetchTransactionDetails = async () => {
        try {
          setFetching(true);
          const { data } = await payoutService.getTransactionDetails({
            transactionId,
          });
          const transactionDetails = data.data;

          console.log(
            'Inside hot bed receipt transactionDetails value received :::',
            transactionDetails
          );

          if (transactionDetails && transactionDetails.bookingId) {
            setHotBedReceiptDetails(transactionDetails);

            console.log(
              'Now received details value OP in hot bed receipt :::',
              typeof transactionDetails,
              details
            );
          } else {
          }
        } catch (error) {
          toast.error('Something went wrong!');
        } finally {
          setFetching(false);
        }
      };
      if (transactionId) fetchTransactionDetails();
    }, [transactionId]);

    return (
      <div className="w-[95%] text-sm lg:w-[65%]  mx-auto">
        {fetching ? (
          <div className="tw-rounded-md tw-p-8 tw-grid tw-place-content-center tw-bg-white">
            Loading...
          </div>
        ) : (
          <div className="tw-bg-white p-4 my-24 tw-rounded-md tw-relative">
            <span
              onClick={onClose}
              className="tw-right-6 tw-top-6 tw-cursor-pointer align-right"
            >
              <IoMdClose size={32} />
            </span>

            <h2 className="text-3xl font-medium">
              Your Receipt from Crewmates
            </h2>
            
            <p>
              Receipt ID: {details.receiptId}{' '}
              {details.transactionDate &&
                `- ${moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY')}`}
            </p>
            <h3 className="text-2xl font-medium mt-2">Reservation Details</h3>
            <p className="my-4">
              {details.bookingId && (
                <>
                  {moment(details.bookingId.checkInDate.split('T')[0]).format(
                    'MMMM Do, YYYY'
                  )}
                  {` - ${moment(
                    details.bookingId.checkOutDate.split('T')[0]
                  ).format('MMMM Do, YYYY')}`}
                </>
              )}
              <br />
              Hot bed <br />
              Room {details.bookingId && details.bookingId.roomName}, Bed{' '}
              {details.bookingId && details.bookingId.bedName} <br />
              Security Deposit: {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}<br />
              Confirmation code :{' '}
              {details.bookingId && details.bookingId.confirmationCode}
            </p>

            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy ? (
              <h4 className="text-xl font-medium">Cancellation Policy</h4>
            ) : null}

            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Relaxed' ? (
              <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                <span className="text-gray-500">Relaxed: </span> Guests can
                receive a full refund up until 24 hours before check-in
              </p>
            ) : null}
            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Moderate' ? (
              <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                <span className="text-gray-500">Moderate: </span> Guests can
                receive a full refund up until 5 days before check-in
              </p>
            ) : null}
            {details.bookingId &&
            details.bookingId.propertyId.cancelationPolicy === 'Strict' ? (
              <p className="tw-text-sm tw-text-gray-500 tw-my-2">
                <span className="text-gray-500">Strict: </span> No refunds
              </p>
            ) : null}
            {/* <p className="tw-text-sm tw-text-gray-500 tw-my-2">
              <span className="text-gray-500">Security Deposit: </span>
              {details.bookingId &&
                details.bookingId.securityDeposite.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
            </p> */}
            <h4 className="text-xl font-medium">Price Breakdown</h4>

            <div className="tw-grid tw-grid-cols-2 tw-text-gray-800 mt-3 d-flex align-items-center justify-content-between        ">
              <p className = 'tw-text-gray-500'>
                {details.bookingId.numberOfDay}{' '}
                {Number(details.bookingId.numberOfDay) > 1 ? 'Nights' : 'Night'}
              </p>
              <p className="tw-text-right tw-text-gray-500">
                {details.bookingId &&
                  `$${parseFloat(
                    details.bookingId.bookingPrice *
                      details.bookingId.numberOfDay
                  )
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              {details.extraGuestCharge > 0 && (
                <>
                  <p className='tw-text-gray-500'>Extra Guest Charge</p>
                  <p className="tw-text-right tw-text-gray-500">
                    {details.bookingId &&
                      `$${parseFloat(details.extraGuestCharge)
                        .toFixed(2)
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}`}
                  </p>
                </>
              )}
              <p className='tw-text-gray-500'>Cleaning Fee</p>
              <p className="tw-text-right tw-text-gray-500">
                {details.bookingId &&
                  `$${parseFloat(details.cleaningFee)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              {
                details.serviceFee  > 0 ? 
                <>
                <p className='tw-text-gray-500'>Service Fee</p>
                  <p className="tw-text-right tw-text-gray-500">
                    {details.bookingId &&
                      `$${parseFloat(details.serviceFee)
                        .toFixed(2)
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}`}
                  </p>
                </>
                :
                <></>
              }      
             

              {details.bookingId && details.discountOfLineholder && (details.discountOfLineholder > 0) ? (
                <React.Fragment>
                  <p className='tw-text-gray-500'>Lineholder Discount</p>
                  <p className="tw-text-right">
                    {`$${parseFloat(details.discountOfLineholder)
                      .toFixed(2)
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}`}
                  </p>
                </React.Fragment>
              ) : null}


              <p className='font-medium tw-font-medium tw-text-gray-500'>Total</p>
              <p className="tw-text-right tw-text-gray-500">
                {details.bookingId &&
                  `$${parseFloat(details.totalPrice)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
            
            </div>
            <hr className="tw-col-span-full" /> 
            <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
                <div className='flex-col flex'>
                  <h4 className="text-xl font-medium mb-3">Payment</h4>
                  <p className='tw-text-gray-500'>{details.cardDetails}</p>
                  <p className='tw-text-gray-500'>{moment(details.transactionDate).tz(details.bookingId.timezone).format('MMMM DD, YYYY h:mm:ss zz')} 
                  {/* EST */}
                  </p>
                </div>
                <p className="tw-text-right">
                  {details.bookingId &&
                    `$${parseFloat(details.totalPrice)
                      .toFixed(2)
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}`}
                </p>
              </div>
              <hr className="tw-col-span-full" />
              <div className="tw-grid tw-grid-cols-2 tw-text-gray-800   ">
              <p className="tw-font-medium font-medium">Amount Paid</p>
              <p className="tw-font-medium tw-text-right font-medium">
                {details.bookingId &&
                  `$${parseFloat(details.totalPrice)
                    .toFixed(2)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}`}
              </p>
              </div>


          </div>
        )}
      </div>
    );
  };

  const toggleRefundReceipt = (refundInfo) => () => {
    setShowRefundReceipt((e) => !e);
    if(refundInfo){
      setRefundDetails(state=>({
        ...state,
        individualRefund:refundInfo
      }))
    } 
  };

  const getRefundMonthOrDate = (refundData) => {
    const date = refundData.createdAt
    ? refundData.createdAt.split("T")[0]
    : refundData.createdAt;
    if(details.isMonthlyCheckout === 2){
      return refundData.dateForIdentifyMonth ? moment(refundData.dateForIdentifyMonth).format('MMMM') : moment(date).format('MMMM')
  }else{
      return details.bookingType === COLD? moment(date).format("MMMM") :moment(
        date
      ).format("MMMM Do, YYYY")
  }
  }

  const showMonthsBesideReceipt = (transactionData) => {
    if(details.isMonthlyCheckout === 2){
        return transactionData.dateForIdentifyMonth ? moment(transactionData.dateForIdentifyMonth).format('MMMM') : moment(transactionData.startDate.split('T')[0]).format('MMMM')
    }else{
        return moment(transactionData.startDate.split('T')[0]).format('MMMM')
    }
}

  return (
    <>
      <div className="tw-grid md:tw-grid-cols-2 boking-space lg:tw-grid-cols-3 tw-text-sm py-4">
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Reservation Id
          </label>
          <p className="tw-text-700">{details.bookingId || '--'}</p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Booking Type
          </label>
          <p className="tw-text-700">
            {details.propertyId && details.propertyId.bookingType}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Confirmation code
          </label>
          <p className="tw-text-700 tw-uppercase">
            {details.confirmationCode || 'TEHWY'}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Listing name
          </label>
          <p className="tw-text-700">
            {details.propertyId && details.propertyId.listingName}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Guest name
          </label>
          <p className="tw-text-700">
            {' '}
            {details.userId && details.userId.name}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Selected Room
          </label>
          <p className="tw-text-700"> {details.selectedRoom}</p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Price
          </label>
          <p className="tw-text-700">
          {/* getWholeBookingPrice */}
          {details.totalPrice &&
              getWholeBookingPrice().toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            {/* {details.totalPrice &&
              details.totalPrice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })} */}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Check In
          </label>
          <p className="tw-text-700">
            {' '}
            {moment(details.checkInDate.split('T')[0]).format('MMMM Do, YYYY')}
          </p>
        </div>
        {details.checkOutDate && (
          <div>
            <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
              Check Out
            </label>
            <p className="tw-text-700">
              {' '}
              {moment(details.checkOutDate.split('T')[0]).format(
                'MMMM Do, YYYY'
              )}
            </p>
          </div>
        )}
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Created At
          </label>
          <p className="tw-text-700">
            {moment(details.createdAt.split('T')[0]).format('MMMM Do, YYYY')}
          </p>
        </div>
        <div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Updated At
          </label>
          <p className="tw-text-700">
            {moment(details.updatedAt.split('T')[0]).format('MMMM Do, YYYY')}
          </p>
        </div>

        <div></div>

        <div></div>
      </div>
      {/*End of reservation details info table list */}
      {/* Refund section started */}
  
      {refundDetails.reufndData.length > 0 && (
        <div className="">
          <h4>Refund Status</h4>
          <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
            {/* <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Created At
            </div> */}
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            {details.bookingType === COLD ? "Month" : "Created At"}
            </div> 
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Amount
            </div>
            <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Action
            </div>
          </div>
          {refundDetails.reufndData && refundDetails.reufndData.map((refund,index)=>{
            return  <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3" key={`refund-${index}`}>
            <div className={`tw-p-2`}>
              <p className="tw-text-700">
                {getRefundMonthOrDate(refund)}
                {/* {moment(
                  refundDetails.createdAt
                    ? refundDetails.createdAt.split("T")[0]
                    : refundDetails.createdAt
                ).format("MMMM Do, YYYY")} */}
              </p>
            </div>
            <div className="tw-p-2 tw-pr-4">
              <p className="tw-text-700">
                {refund.refundAmount &&
                  parseFloat(refund.refundAmount)
                    .toFixed(2)
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </p>
            </div>
            <div
              className="tw-p-2 tw-pr-4 tw-cursor-pointer tw-text-primary"
              onClick={toggleRefundReceipt(refund)}
            >
              View Receipt
            </div>
          </div>
          })}
         
          {/* ))} */}
        </div>
      )}

      {/* Refund section end */}

      {/* Div start to show the Payment plan which includes month,amount and action column in it */}
      {/* Change added on 10-28-2022 */}
      {/* {(details.bookingType === "COLD" ||
        details.bookingType === "Cold bed") && ( */}
      <div className="">
        <h4>Payment Plan</h4>
        <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            {details.bookingType === COLD ? 'Month' : 'Booking'}
          </div>
          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            Amount
          </div>
          <div className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
            Action
          </div>
        </div>
        {transactionList.map((data, ind) => (
          <div key={data.id} className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
            <div
              className={`tw-p-2 ${details.bookingType === COLD && 'tw-pr-4'}`}
            >
              {details.bookingType === COLD
                // ? moment(data.startDate.split('T')[0]).format('MMMM')
                ?showMonthsBesideReceipt(data)
                : `${moment(details.checkInDate.split('T')[0]).format(
                    'MMMM Do, YYYY'
                  )} - ${moment(details.checkOutDate.split('T')[0]).format(
                    'MMMM Do, YYYY'
                  )}`}
            </div>
            <div className="tw-p-2 tw-pr-4">
              {data.bookingId &&
                parseFloat(data.totalPrice)
                  .toFixed(2)
                  .toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
            </div>
            <div
              className="tw-p-2 tw-pr-4 tw-cursor-pointer tw-text-primary"
              onClick={() => {
                setTransactionId(data.id);
              }}
            >
              View Receipt
            </div>
          </div>
        ))}

        {!Boolean(transactionList.length) && (
          <div className="tw-p-8 tw-grid tw-place-content-center">
            No Transactions found
          </div>
        )}
      </div>
      {/* )} */}

      {/* Div ends to show the Payment plan */}

      <br />
      <div className="tw-col-span-full ">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => handleMessageNavigation()}
        >
          Message History
        </button>
      </div>
      {showRefundReceipt && (
                <ReceiptModal className="w-full  " onClose={toggleRefundReceipt(null)}>
                    <RefundReceipt details={refundDetails.individualRefund} onClose={toggleRefundReceipt(null)} />
                </ReceiptModal>
            )}
      {/* <div className="pb-4"> */}
      {transactionId && (
        <ReceiptModal
          className="w-full  "
          onClose={() => setTransactionId(null)}
        >
          {details.bookingType === 'Cold bed' ? (
            <ColdBedReceipt
              transactionId={transactionId}
              onClose={() => setTransactionId(null)}
            />
          ) : (
            <HotBedReceipt
              transactionId={transactionId}
              onClose={() => setTransactionId(null)}
            />
          )}
        </ReceiptModal>
      )}
      {/* </div> */}
    </>
  );
}

export default ReservationDetails;
